<template>
    <section class="nav-new-bar-sec" :class="{ 'home-sec': checkIsLogin(), 'business-type': siteSettings?.business_type == 1 }">
        <div class="nav-middle-menu " ref="navMenu">
            <ul>               
                
                <li>
                    <router-link :to="{ name: 'Home' }" @click="type = 'HOME'" :class="{ 'active': $route.name=='Home'}">
                        <img loading="lazy" src="@/assets/images/menu-home.png" alt="menu-home" /> <br />
                        <span>{{translatedLangData('home','Home')}}</span>
                    </router-link>
                </li>

                <li>
                    <router-link to="/inplay" @click="type = 'IN_PLAY'" :class="{ 'active': $route.path=='/inplay' }">
                        <img loading="lazy" src="@/assets/images/menu-inplay.png" alt="menu-inplay" /> <br />
                        <span>{{translatedLangData('inplay-footer-label','In-Play')}}</span>
                    </router-link>
                </li>
                <li v-for="(sport, n_index) in primary_non_custom_sports" :key="n_index">
                    <router-link :to="'/sports/' + sport.name + '/' + sport.id" @click="type = sport.name"
                        :class="{ 'active':$route.path.includes('/sports/'+sport.name) }">
                        <img loading="lazy" :src="getSportImage(sport.id)" alt="menu-tennis">
                        <br />
                        <span> {{ sport?.name == 'soccer' ? 'Football' : sport?.name }} </span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'matka' }" @click="type = 'MATKA'" :class="{ 'active': $route.path.includes('/matka') }">
                        <img class="matka-filter" loading="lazy" src="@/assets/images/icon-matka.svg" alt="icon-matka" /> <br />
                        <span> {{translatedLangData('matka','Matka')}}</span>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'cricketFight' }" @click="type = 'cricket-fight'" :class="{ 'active': $route.path.includes('/cricket-fight') }">
                        <img loading="lazy" src="@/assets/images/menu-cricket-fight.webp" alt="menu-cricket-fight" /> <br />
                        <span> {{translatedLangData('cricket-fight','Cricket Fight')}}</span>
                    </router-link>
                </li>
                <li class="hightlight-nav-menu">
                    <router-link :to="{ name: 'Games', params: { type: 'casino'} }" :class="{ 'active': $route.params.type == 'casino' }">
                        <img loading="lazy" src="@/assets/images/menu-casino.png" alt="menu-casino" /> <br />
                        <span>{{translatedLangData('casino','Casino')}}</span>
                    </router-link>
                </li>
                <li class="hightlight-nav-menu">
                    <router-link :to="{ name: 'Games', params: { type: 'evolution'} }" :class="{ 'active': $route.params.type == 'evolution' }">
                        <img loading="lazy" src="@/assets/images/casino-title-menu.png" alt="casino-title-menu" /> <br />
                        <span> {{translatedLangData('evolution','Evolution')}}</span>
                    </router-link>
                </li>
                <li v-for="(sport, n_index) in secondary_non_custom_sports" :key="n_index">
                    <router-link :to="'/sports/' + sport.name + '/' + sport.id" @click="type = sport.name"
                        :class="{ 'active':$route.path.includes('/sports/'+sport.name) }">
                        <img loading="lazy" :src="getSportImage(sport.id)" alt="menu-tennis">
                        <br />
                        <span> {{ sport?.name == 'soccer' ? 'Football' : sport?.name }} </span>
                    </router-link>
                </li>
                <li v-for="(c_sport, c_index) in custom_sports" :key="c_index">
                    <router-link :to="'/sports/' + c_sport.name + '/' + c_sport.id" @click="type = c_sport.name"
                        :class="{ 'active': $route.path.includes('/sports/'+c_sport.name) }">
                        <img loading="lazy" :src="getSportImage(c_sport.id)" alt="menu-tennis"><br />
                        <span> {{ c_sport.name }} </span>
                    </router-link>
                </li>
            </ul>
        </div>
        <div class="mobile-toggle-btn-sec">
            <div class="mobile-search-menu">
                <button type="button" data-bs-toggle="offcanvas" href="#offcanvasExample11" role="button" aria-controls="offcanvasExample11" aria-label="Toggle menu">
                <span id="menu-toggle1" class="menu-toggle">
                    <img v-if="menuShow" @click="addCloseButton()" src="@/assets/images/Group-menu-icon.webp" alt="Open menu" class="group-toggle-icon">
                    <img v-else src="@/assets/images/close-sidebar.webp" alt="Close menu" class="close-side-bar-btn">
                </span>
            </button>
            </div>
        </div>    
        <div class="commentry-sec" v-if="siteSettings?.announcement">
                <div class="commentry-fixed-img">
                    <img loading="lazy" src="@/assets/images/commentary.webp" alt="commentary">
                </div>
                <div class="moving-text">
                    <marquee :style="{ color: siteSettings?.announcement?.color_code }" onmouseover="this.stop();" onmouseout="this.start();">{{ siteSettings?.announcement?.msg }}</marquee>
                </div>
        </div>
    </section>

</template>

<script>
import { mapGetters } from 'vuex';
import click_api from '../../services/click_api';
import * as apiName from '../../services/urls';

export default {
    name: 'NavBar',
    inject: ['translatedLangData'],
    data() {
        return {
            type: 'HOME',
            non_custom_sports: [],
            custom_sports: [],
            // menuShow:true,
        }
    },    
    computed: {
        ...mapGetters({
			menuShow: 'menuShow'
		}),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        sortedNonCustomSports() {
            const order = ['cricket', 'football', 'tennis'];
            return this.non_custom_sports.sort((a, b) => {
                const aIndex = order.indexOf(a.name.toLowerCase());
                const bIndex = order.indexOf(b.name.toLowerCase());

                if (aIndex !== -1 || bIndex !== -1) {
                return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
                }
                return a.rank - b.rank;
            });
        },
        primary_non_custom_sports(){
            return this.$store.getters.sports_list?.non_custom?.filter(sport => [1,2,3,4].includes(sport.rank))
        },
        secondary_non_custom_sports(){
            return this.$store.getters.sports_list?.non_custom?.filter(sport => ![1,2,3,4].includes(sport.rank))
        },

    },
    watch: {
        $route(to, from) {
            this.$nextTick(() => {
                this.scrollToSelected();
            });
        }
    },
    created() {
        this.get_sportsList()
    },
    mounted(){
        this.$store.dispatch('setMenuShow', true)
    },
    methods: { 
        scrollToSelected() {
            this.$nextTick(() => {
                const navMenu = this.$refs.navMenu;
                const selectedElement = navMenu.querySelector('a.active');
                if (selectedElement) {
                    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            });
        },     
        addCloseButton(){
            const checkBackdrop = document.querySelector('.mobile-offcanvass .offcanvas-backdrop.fade.show');
            checkBackdrop.addEventListener('click', this.handleBackdropClick);                     
            checkBackdrop && (this.$store.dispatch('setMenuShow', false));
        },
        handleBackdropClick(){
            const checkBackdrop = document.querySelector('.mobile-offcanvass .offcanvas-backdrop.fade.show');
            removeEventListener('click', checkBackdrop);
            this.$store.dispatch('setMenuShow', true)
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        get_sportsList() {
            this.$store.dispatch('setGlobalLoader', true);
            click_api.get(apiName.GET_SPORTS).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if (all_sports[i].is_custom == 0) {
                                this.non_custom_sports.push(all_sports[i])
                            } else {
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.sortedNonCustomSports
                        this.$store.dispatch('setSportsList',sport_list)
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) { 
                }
            });
        },
        getSportImage(sportId) {
            const imageMap = {
                4: require("@/assets/images/sports-cricket.png"),
                1: require("@/assets/images/sports-soccer.png"),
                2: require("@/assets/images/sports-tennis.png"),
                1477: require("@/assets/images/sports-rugby-league.png"),
                6: require("@/assets/images/sports-boxing.png"),
                5: require("@/assets/images/sports-rugby-union.png"),
                3: require("@/assets/images/sports-golf.png"),
                27454571: require("@/assets/images/sports-esports.png"),
                2152880: require("@/assets/images/sports-gaelic-football.png"),
                998917: require("@/assets/images/sports-volleyball.png"),
                7524: require("@/assets/images/sports-ice-hockey.png"),
                7522: require("@/assets/images/sports-basketball.png"),
                7511: require("@/assets/images/sports-baseball.png"),
                6423: require("@/assets/images/sports-american-football.png"),
                6422: require("@/assets/images/sports-snooker.png"),
                11: require("@/assets/images/sports-cycling.png"),
                8: require("@/assets/images/sports-motor-sport.png"),
                2378961: require("@/assets/images/sports-politics.png"),
                468328: require("@/assets/images/sports-handball.png"),
                61420: require("@/assets/images/sports-australian-rules.png"),
                3503: require("@/assets/images/sports-darts.png"),
                10: require("@/assets/images/sports-special-bets.png"),
                26420387: require("@/assets/images/sports-mixed-martial-arts.png"),
                4339: require("@/assets/images/sports-greyhound-racing.png"),
                7: require("@/assets/images/sports-horse-racing.png"),
            };
            return imageMap[sportId] || require("@/assets/images/sports-no.png");
        },
    }
}
</script>