<template>
    <div class="left-side-bar-sec" id="show-m-toggle">
        <div class="app-lft-sidebar">
            <div v-if="siteSettings" class="offcanvass-logo-sec mobile-logo">
                <img loading="lazy" :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo" alt="Site logo">
            </div> 
            <div  class="depo-and-wdrl-sec"> 
                <ul> 
                    <li>
                        <router-link to="/" class="multi-mark-bg home-btn-m" @click="closeModal();">
                            <img loading="lazy" src="@/assets/images/menu-home.webp" alt="menu-home">
                            <span>{{translatedLangData('home','Home')}}</span>
                        </router-link>
                    </li>
                    <li v-if="siteSettings?.business_type == 2 && checkIsLogin()">
                        <router-link :to="{name:'wallet-gateway'}" class="depo-side-bg" @click="closeModal();">
                            <img loading="lazy" src="@/assets/images/deposit-icon.webp" alt="deposit-icon">
                            <span>{{translatedLangData('deposit','Deposit')}}</span>
                        </router-link>
                    </li>
                    <li v-if="siteSettings?.business_type == 2 && checkIsLogin()">
                        <router-link :to="{name:'wallet-gateway'}" class="wrdl-side-bg" @click="closeModal();">
                            <img loading="lazy" src="@/assets/images/withdrawal-icon.webp" alt="withdrawal-icon">
                            <span>{{translatedLangData('withdrawal','Withdrawal')}}</span>
                        </router-link>
                    </li>
                    
                </ul>
            </div>

            <div class="multi-market-tab" v-if="checkIsLogin()">
                <ul>
                    <li>
                    <router-link to="/multi-market" class="multi-mark-bg">
                        <img loading="lazy" src="@/assets/images/menu-market.webp" alt="menu-market" class="multi-img">
                        <span data-bs-dismiss="offcanvas">{{translatedLangData('multi-market','Multi Market')}}</span>
                    </router-link>
                </li>
                </ul>
            </div>

            <div class="accordion side-menu-tabs">
                <div class="accordion-item" v-for="(sport, n_index) in primary_non_custom_sports" :key="n_index">
                    <h2 class="accordion-header" id="headingOne2">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            @click="getLeagues(sport)" :data-bs-target="'#collapseOne1' + n_index" aria-expanded="true"
                            :aria-controls="'collapseOne1' + n_index">
                            <span class="side-menu-heading">
                                <span class="img-side-logo">
                                    <img loading="lazy" :src="getSportImage(sport.id)" alt="menu-tennis">
                                </span>
                                <span class="sports-name-h">{{ sport.name == 'soccer' ? 'Football' : sport.name }}</span>
                            </span>
                        </button>
                    </h2>
                    <div :id="'collapseOne1' + n_index" class="accordion-collapse collapse" aria-labelledby="headingOne2"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body" v-if="sport.leagueList && Object.keys(sport.leagueList).length != 0">                            
                            <div class="more-details-menu" v-for="(league, l_index) in sport.leagueList" :key="l_index">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        @click="getMatches(league)" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{{ l_index }}</span>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="(match, m_index) in matchesList" :key="m_index">
                                            <a @click="clickOnSportItem(match);replaceMenuIcon()" class="dropdown-item"
                                                href="javascript:void(0);">
                                                <span>{{ match.event_name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-body" v-else>
                            <div class="sidebarmenu-not-found">
                                {{ translatedLangData('no-data-found','No Data Found')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingten">
                        <router-link @click="this.closeModal();" to="/matka" class="accordion-button no-collapse-btn collapsed"
                            style="padding: 9px 10px;">
                            <span class="side-menu-heading">
                                <span class="img-side-logo"><img loading="lazy" src="@/assets/images/matka-icon-new.svg" alt="matka-icon-new" /></span>
                                <span class="sports-name-h">{{translatedLangData('matka','Matka')}}</span>
                            </span>
                        </router-link>
                    </h2>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingeight">
                        <router-link @click="this.closeModal();" :to="{name: 'Games', params: 'casino'}"  class="accordion-button no-collapse-btn collapsed"
                            style="padding: 9px 10px">
                            <span class="side-menu-heading">
                                <span class="img-side-logo"><img loading="lazy" src="@/assets/images/menu-99999.png" alt="menu-99999" /></span>
                                <span class="sports-name-h">{{translatedLangData('casino','Casino')}}</span>
                            </span>
                        </router-link>
                    </h2>
                </div>

                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingten">
                        <router-link @click="this.closeModal();" :to="{name: 'Games', params: 'evolution'}" class="accordion-button no-collapse-btn collapsed"
                            style="padding: 9px 10px">
                            <span class="side-menu-heading">
                                <span class="img-side-logo"><img loading="lazy" src="@/assets/images/menu-99998.png" alt="menu-99998" /></span>
                                <span class="sports-name-h">{{translatedLangData('evolution','Evolution')}}</span>
                            </span>
                        </router-link>
                    </h2>
                </div>
                <div class="accordion-item" v-for="(sport, sn_index) in secondary_non_custom_sports" :key="sn_index">
                    <h2 class="accordion-header" id="headingOne3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            @click="getLeagues(sport)" :data-bs-target="'#collapseOne3' + sn_index" aria-expanded="true"
                            :aria-controls="'collapseOne3' + sn_index">
                            <span class="side-menu-heading">
                                <span class="img-side-logo">
                                    <img loading="lazy" :src="getSportImage(sport.id)" alt="menu-tennis">
                                </span>
                                <span class="sports-name-h">{{ sport.name == 'soccer' ? 'Football' : sport.name }}</span>
                            </span>
                        </button>
                    </h2>
                    <div :id="'collapseOne3' + sn_index" class="accordion-collapse collapse" aria-labelledby="headingOne3"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body" v-if="sport.leagueList && Object.keys(sport.leagueList).length != 0">                            
                            <div class="more-details-menu" v-for="(league, sl_index) in sport.leagueList" :key="sl_index">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        @click="getMatches(league)" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{{ sl_index }}</span>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="(match, sm_index) in matchesList" :key="sm_index">
                                            <a @click="clickOnSportItem(match);replaceMenuIcon()" class="dropdown-item"
                                                href="javascript:void(0);">
                                                <span>{{ match.event_name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-body" v-else>
                            <div class="sidebarmenu-not-found">
                                {{ translatedLangData('no-data-found','No Data Found')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item" v-for="(sport, cus_index) in custom_sports" :key="cus_index">
                    <h2 class="accordion-header" id="headingOne4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            @click="getLeagues(sport)" :data-bs-target="'#collapseOne4' + cus_index" aria-expanded="true"
                            :aria-controls="'collapseOne4' + cus_index">
                            <span class="side-menu-heading">
                                <span class="img-side-logo">
                                    <img loading="lazy" :src="getSportImage(sport.id)" alt="menu-tennis">
                                </span>
                                <span class="sports-name-h">{{ sport.name == 'soccer' ? 'Football' : sport.name }}</span>
                            </span>
                        </button>
                    </h2>
                    <div :id="'collapseOne4' + cus_index" class="accordion-collapse collapse" aria-labelledby="headingOne4"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body" v-if="sport.leagueList && Object.keys(sport.leagueList).length != 0">                            
                            <div class="more-details-menu" v-for="(league, cuslst_index) in sport.leagueList" :key="cuslst_index">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        @click="getMatches(league)" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span>{{ cuslst_index }}</span>
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li v-for="(match, cusmat_index) in matchesList" :key="cusmat_index">
                                            <a @click="clickOnSportItem(match);replaceMenuIcon()" class="dropdown-item"
                                                href="javascript:void(0);">
                                                <span>{{ match.event_name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-body" v-else>
                            <div class="sidebarmenu-not-found">
                                {{ translatedLangData('no-data-found','No Data Found')}}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="checkIsLogin()" class="download-apk-bx">
                    <a href="Javascript:void(0);" @click="closeModal(); $emit('openRulesMOdal')">
                        <span class="side-menu-heading">
                            <span class="img-side-logo">
                                <img src="@/assets/images/menu-rules.png"> 
                            </span>
                            <span class="sports-name-h">{{translatedLangData('rules','Rules')}}</span>
                        </span>
                    </a>
                </div>
                <div class="download-apk-bx">
                    <a href="#install-apk-model" @click="closeModal();" data-bs-toggle="modal">
                        <span class="side-menu-heading">
                            <span class="img-side-logo">
                                <img loading="lazy" src="@/assets/images/project-apk-icon.webp">
                            </span>
                            <span class="sports-name-h">{{translatedLangData('download-apk','Download APK')}}</span>
                        </span>
                    </a>
                </div>
                <div class="instant-id-bx" >
                    <strong v-if="siteSettings?.social_link?.whatsappno1">{{translatedLangData('get-instant-id-on-whatsapp','Get Instant ID On Whatsapp')}}</strong>
                    <ul v-if="siteSettings && siteSettings?.social_link">
                        <li v-if="siteSettings?.social_link?.whatsappno1" @click="closeModal();">
                            <a :href="'//wa.me/' + siteSettings.social_link?.whatsappno1" target="_blank"><img
                                    src="@/assets/images/whatapp-icon.webp"> 
                                <span class="sports-name-h">{{translatedLangData('whatsapp-now','Whatsapp Now')}}</span></a>
                        </li>
                        <li v-if="siteSettings.social_link?.telegram_link && siteSettings?.business_type==2" @click="closeModal();">
                            <a :href="siteSettings.social_link?.telegram_link" target="_blank"><img
                                    src="@/assets/images/telegram-icon.webp"> 
                                <span class="sports-name-h">{{translatedLangData('follow-on-telegram','Follow on telegram')}}</span></a>
                        </li>
                        <li v-if="siteSettings.social_link?.instagram_link && siteSettings?.business_type==2" @click="closeModal();">
                            <a :href="siteSettings.social_link?.instagram_link" target="_blank"><img
                                    src="@/assets/images/instagrame.webp"> 
                                <span class="sports-name-h">{{translatedLangData('follow-on-instagram','Follow on instagram')}}</span></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="banner-side-img-sec">
                <BannerCarousel />
            </div>
            
        </div>
    </div>
    
</template>
<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import BannerCarousel from '@/shared/components/banner/BannerCarousel.vue'
import click_api from '../../services/click_api';
import * as apiName from '../../services/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'SideMenu',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
        BannerCarousel,
    },
    inject: ['translatedLangData'],
    data() {
        return {
            sportsData: [],
            leagues_list: {},
            matchesList: [],
            screenSize: window.innerWidth,
            mobileSideMenu: false
        }
    },
    mounted() {
        if (this.screenSize < 991) {
            this.mobileSideMenu = true
        }
    },
    computed: { 
        custom_sports(){
            return this.sports_list?.custom ?? [];
        },
        non_custom_sports(){
            return this.sports_list?.non_custom ?? [];
        },
        ...mapGetters({
			menuShow: 'menuShow',
			sports_list: 'sports_list'
		}),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        primary_non_custom_sports(){
            return this.sports_list?.non_custom?.filter(sport => [1,2,3,4].includes(sport.rank))
        },
        secondary_non_custom_sports(){
            return this.sports_list?.non_custom?.filter(sport => ![1,2,3,4].includes(sport.rank))
        },
    },
    methods: {
        replaceMenuIcon(){
            this.$store.dispatch('setMenuShow', true)
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        getLeagues(sport) {
            if (sport.leagueList) {
                return;
            }
            this.loading = true;
            click_api.get(apiName.MATCHES_BY_SPORT_ID + sport.id).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.sportsData = response.data.data
                        sport.leagueList = {};

                        this.sportsData?.forEach(item => {
                            const leagueName = item.league_name;
                            if (!sport.leagueList[leagueName]) {
                                sport.leagueList[leagueName] = []
                            }
                            sport.leagueList[leagueName].push(item)
                        });


                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error.data.message);
                }
            });
        },
        getMatches(matches) {
            this.matchesList = matches
        },
        clickOnSportItem(match) {
            this.closeModal();
            this.$router.push('/sports-event-detail/' + match.event_id)
        },
        closeModal() {
            this.replaceMenuIcon()
            this.$emit('close-modal');
        },
        getSportImage(sportId) {
            const imageMap = {
                4: require("@/assets/images/sports-cricket.png"),
                1: require("@/assets/images/sports-soccer.png"),
                2: require("@/assets/images/sports-tennis.png"),
                1477: require("@/assets/images/sports-rugby-league.png"),
                6: require("@/assets/images/sports-boxing.png"),
                5: require("@/assets/images/sports-rugby-union.png"),
                3: require("@/assets/images/sports-golf.png"),
                27454571: require("@/assets/images/sports-esports.png"),
                2152880: require("@/assets/images/sports-gaelic-football.png"),
                998917: require("@/assets/images/sports-volleyball.png"),
                7524: require("@/assets/images/sports-ice-hockey.png"),
                7522: require("@/assets/images/sports-basketball.png"),
                7511: require("@/assets/images/sports-baseball.png"),
                6423: require("@/assets/images/sports-american-football.png"),
                6422: require("@/assets/images/sports-snooker.png"),
                11: require("@/assets/images/sports-cycling.png"),
                8: require("@/assets/images/sports-motor-sport.png"),
                2378961: require("@/assets/images/sports-politics.png"),
                468328: require("@/assets/images/sports-handball.png"),
                61420: require("@/assets/images/sports-australian-rules.png"),
                3503: require("@/assets/images/sports-darts.png"),
                10: require("@/assets/images/sports-special-bets.png"),
                26420387: require("@/assets/images/sports-mixed-martial-arts.png"),
                4339: require("@/assets/images/sports-greyhound-racing.png"),
                7: require("@/assets/images/sports-horse-racing.png"),
            };
            return imageMap[sportId] || require("@/assets/images/sports-no.png");
        },
    },
    emits: ['close-modal']
}
</script>